<template>
    <el-dialog
        title="立即沟通"
        :visible.sync="showcom"
        width="55%"
        @opened="open"
        :before-close="handleClose">
        <div>
            <el-form ref="form" :model="form" label-width="160px">
                <el-form-item label="响应企业">
                    <el-input v-model="form.memberName" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="响应人数">
                    <el-input v-model="form.needPerson" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="联系方式">
                    <el-input v-model="form.mobile" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="用工协议">
                    <div class="flex flex-wrap">
                        <div style="width: 50%;"><a href="https://ftp.srrlzysc.com/附件1-2(2)(1).wps" target="_blank">附件1-2(2)(1).wps</a></div>
                        <div style="width: 50%;"><a href="https://ftp.srrlzysc.com/共享用工协议.docx" target="_blank">共享用工协议.docx</a></div>
                        <div style="width: 50%;"><a href="https://ftp.srrlzysc.com/饶就业字〔2021〕12号 上饶市“共享用工”协调机制方案(1).pdf" target="_blank">饶就业字〔2021〕12号 上饶市“共享用工”协调机制方案(1).pdf</a></div>
                        <div style="width: 50%;">
                            <a data-v-644b57ec="" href="https://ftp.srrlzysc.com/上饶市共享员工信息交换登记表.docx" target="_blank">上饶市共享员工信息交换登记表.docx</a>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="是否同意协议">
                    <el-radio-group v-model="isArgeen">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="响应描述">
                    <el-input type="textarea" v-model="form.description" style="width: 600px;"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirm" :disabled="isArgeen?false:true">立即沟通</el-button>
      </span>
    </el-dialog>
</template>

<script>

import _api from '@/api/index'
export default{

    props:{
        showcom:{
            type:Boolean,
            default:false
        },
        selectobj:{
            type:Object,
            default:null
        }
    },
    data(){
        return{
            isArgeen:false,
            form:{
                memberName:null,
                needPerson:null,
                mobile:null,
                description:null,
                pid:null,
            }
        }
    },
    methods:{
        open(){
            this.form.pid=this.selectobj.id
        },
        handleClose(){
            this.form={
                memberName:null,
                needPerson:null,
                mobile:null,
                description:null,
                pid:null,
            }
            this.$emit("update:showcom",false)
        },
        confirm(){
            if(!this.form.memberName){
                this.$message.warning("请输入企业名称！")
                return
            }

            _api.post("/flexible/addInfo",this.form).then(res=>{
                if(res.code==200){
                    this.$message.success("操作成功！")
                    this.handleClose()
                }
            })


        }
    }
}
</script>


