<template>
    <el-dialog
        title="沟通记录"
        :visible.sync="showrecord"
        width="50%"
        @opened="open"
        :before-close="handleClose">
        <div v-loading="loading">
            <el-table
                :data="tableData"

                style="width: 100%">
                <el-table-column
                    prop="memberName"
                    label="响应企业">
                </el-table-column>
                <el-table-column
                    prop="needPerson"
                    label="响应人数">
                </el-table-column>
                <el-table-column
                    prop="mobile"
                    label="联系方式">
                </el-table-column>
                <el-table-column
                    prop="description"
                    label="响应描述">
                </el-table-column>
            </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
<!--        <el-button type="primary" @click="confirm">确 定</el-button>-->
      </span>
    </el-dialog>
</template>

<script>
import _api from '@/api/index'
export default{
    props:{
        showrecord:{
            type:Boolean,
            default:false
        },
        selectobj:{
            type:Object,
            default:null
        }
    },
    data(){
        return{
            loading:false,
            tableData:[]
        }
    },
    methods:{
        open(){
            this.getList()
        },
        getList(){
            this.loading=true
            _api.get('/flexible/getInfoList',{pid:this.selectobj.id}).then(res=>{
                this.loading=false
                this.tableData=res?.data || []
            })
        },
        handleClose(){
            this.tableData=[]
            this.$emit("update:showrecord",false)
        },
        confirm(){

        }
    }
}
</script>


