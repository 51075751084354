<template>
    <div class="sharebox padding-sm">
        <div class="flex align-center justify-between">
            <div class="flex align-center">
                <el-select
                    v-model="searchForm.memberId"
                    filterable
                    remote
                    clearable
                    reserve-keyword
                    @change="search"
                    placeholder="请输入企业名称"
                    :remote-method="remoteMethod"
                    :loading="loading">
                    <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.companyName"
                        :value="item.id">
                    </el-option>
                </el-select>
                <el-cascader
                    style="margin-left: 6px;"
                    v-model="searchForm.industryId"
                    :options="allIndustrList"
                    :props="jobProps"
                    @change="search"
                    placeholder="用工类型"
                    :clearable="true"
                >
                </el-cascader>
<!--                <el-select v-model="searchForm.type" filterable  placeholder="选择供需方" style="margin-left:9px;">-->
<!--                    <el-option label="供方" value="供方"></el-option>-->
<!--                    <el-option label="需方" value="需方"></el-option>-->
<!--                </el-select>-->
            </div>
            <div class="flex align-center">
                <el-button type="primary" @click="openAdd">{{ isAdd?'取消发布':'发布信息' }}</el-button>
            </div>
        </div>
        <div class="addbox" v-if="isAdd">
            <el-form ref="form" :model="form" label-width="160px">
                <el-form-item label="企业名称">
                    <el-select
                        v-model="form.memberId"
                        filterable
                        remote
                        reserve-keyword
                        @change="changeCompany"
                        placeholder="请输入关键词"
                        :remote-method="remoteMethod"
                        :loading="loading">
                        <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.companyName"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用工类型">
                    <el-cascader
                        v-model="form.industryId"
                        :options="allIndustrList"
                        :props="jobProps"
                        :clearable="true"
                        @change="handleCascaderChange"

                    >
                    </el-cascader>
                </el-form-item>
                <el-form-item label="供方/需方">
                    <el-select v-model="form.supplyStr" filterable  placeholder="供方/需方" >
                        <el-option label="供方" value="供方"></el-option>
                        <el-option label="需方" value="需方"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用工人数">
                    <el-input v-model="form.needPerson" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="联系方式">
                    <el-input v-model="form.mobile" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="用工协议">
                   <div class="flex flex-wrap">
                       <div style="width: 50%;"><a href="https://ftp.srrlzysc.com/附件1-2(2)(1).wps" target="_blank">附件1-2(2)(1).wps</a></div>
                       <div style="width: 50%;"><a href="https://ftp.srrlzysc.com/共享用工协议.docx" target="_blank">共享用工协议.docx</a></div>
                       <div style="width: 50%;"><a href="https://ftp.srrlzysc.com/饶就业字〔2021〕12号 上饶市“共享用工”协调机制方案(1).pdf" target="_blank">饶就业字〔2021〕12号 上饶市“共享用工”协调机制方案(1).pdf</a></div>
                       <div style="width: 50%;">
                           <a data-v-644b57ec="" href="https://ftp.srrlzysc.com/上饶市共享员工信息交换登记表.docx" target="_blank">上饶市共享员工信息交换登记表.docx</a>
                       </div>
                   </div>
                </el-form-item>
                <el-form-item label="是否同意协议">
                    <el-radio-group v-model="isAgreen">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="用工描述">
                    <el-input type="textarea" v-model="form.description" style="width: 600px;"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :disabled="isAgreen?false:true" @click="onSubmit">确认添加</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="shareList margin-top-sm ">
            <div class="shareitem flex align-center justify-between" v-for="(item,index) in list">
                <div class="item-info">
                    <strong>{{ item.industryName }}</strong>
                    <div  class="info-place">
                        <i  class="el-icon-location iconstyle" style="color: rgb(41, 141, 255);"></i>
                        {{ item.supplyStr }}：{{ item.memberName }}
                    </div>
                    <div  class="info-place">
                        <i class="el-icon-message-solid iconstyle" style="color: rgb(41, 141, 255);"></i>
                        用工描述：{{ item.description }}
                    </div>
                </div>
                <div class="itemcontent">
                    <div  class="info-place">
                        <i class="el-icon-s-custom" style="color: rgb(41, 141, 255);"></i>
                        创建时间:{{ item.createTime }}
                    </div>
                </div>
                <div class="item-order">
                    <div><el-button type="primary" style="width: 100%;" @click="openDetail(item)">查看详情</el-button></div>
                    <div><el-button type="primary" style="width: 100%;"  @click="openCom(item)">立即沟通</el-button></div>
                    <div><el-button type="primary" style="width: 100%;" @click="openRecord(item)">沟通记录</el-button></div>
                </div>
            </div>
            <div>
                <app-pagination
                    @handleCurrentChange="handleCurrentChange"
                    :total="total"
                ></app-pagination>
            </div>

        </div>
        <showDetail :show.sync="show" :selectobj="selectobj" />
        <communication :showcom.sync="showcom" :selectobj="selectobj" />
        <record :showrecord.sync="showrecord" :selectobj="selectobj" />
    </div>
</template>

<script>
    import showDetail from "./showDetail.vue";
    import communication from "./communication.vue";
    import record from './record.vue'
    import userRequest from "../../../api/user";
    import AppPagination from "../../../components/pagination/AppPagination";
    import _api from '@/api/index'
    export default {
        components:{
            AppPagination,
            showDetail,
            communication,
            record,
        },
        data(){
            return{
                jobProps: {
                    //级联
                    expandTrigger: "hover",
                    label: "name",
                    value: "id",
                    children: "children",
                },
                allIndustrList:[],
                searchForm:{
                    page: 1,
                    size: 10,
                    memberId:null,
                    industryId:null,

                },
                isAgreen:false,
                options:[],
                form:{
                    memberId:null,
                    memberName:null,
                    industryId:null,
                    supplyStr:null,
                    needPerson:null,
                    mobile:null,
                    description:null,
                },
                showrecord:false,
                isAdd:false,
                showcom:false,
                show:false,
                selectobj:null,
                loading:false,
                list:[],
                total:0,
                loading2:false,

            }
        },
        created() {
            this.getList()
            this.getAllIndustry()
        },
        methods:{
            getList(){
                let industryIdstr=this.searchForm.industryId && this.searchForm.industryId.length>0?this.searchForm.industryId.join(','):null
                _api.get('/flexible',{...this.searchForm,industryId:industryIdstr}).then(res=>{
                    this.list=res?.data?.list || []
                    this.total=res?.data?.total || 0
                })
            },
            search(){
                this.searchForm.page=1
                this.getList()
            },
            handleCurrentChange(num) {
                this.searchForm.page=num
                this.getList()
            },
            changeCompany(){
                let findobj=this.options.find((n)=>n.id==this.form.memberId)
                console.log(findobj)
                this.form.memberName=findobj.companyName
            },
            remoteMethod(query) {
                if (query !== '') {
                    this.loading = true;
                    _api.get('/flexible/getMemberList',{memberName:query}).then(res=>{
                        this.loading = false;
                        this.options = res.data.filter(item => {
                            return item.companyName.toLowerCase()
                                .indexOf(query.toLowerCase()) > -1;
                        });
                    })
                } else {
                    this.options = [];
                }
            },
            onSubmit(){

                if(!this.form.memberId){
                    this.$message.warning("请选择企业！")
                    return
                }
                if(!this.form.industryId){
                    this.$message.warning("请选择行业！")
                    return
                }

                _api.post('/flexible',{...this.form,industryId:this.form.industryId.join(",")}).then(res=>{
                    if(res.code==200){
                        this.$message.success("添加成功！")
                        this.form={
                            memberId:null,
                            memberName:null,
                            industryId:null,
                            supplyStr:null,
                            needPerson:null,
                            mobile:null,
                            description:null,
                        }
                    }
                })

            },
            getAllIndustry() {
                userRequest
                    .getAllIndustry()
                    .then((res) => {

                        if (res.code === 200) {
                            this.allIndustrList = res.data;
                            setTimeout(() => {
                                this.categoryStatus = false;
                            }, 500);
                        }
                    })
                    .catch(() => {
                        this.categoryStatus = false;
                    });
            },
            handleCascaderChange() {

            },
            openRecord(row){
                this.selectobj=row
                this.showrecord=true
            },
            openCom(row){
                this.selectobj=row
                this.showcom=true
            },
            openDetail(row){
                this.selectobj=row
                this.show=true
            },
            openAdd(){
                if(this.isAdd){
                    this.form={
                        memberId:null,
                        memberName:null,
                        industryId:null,
                        supplyStr:null,
                        needPerson:null,
                        mobile:null,
                        description:null,
                    }
                }
                this.isAdd=!this.isAdd



            }
        }
    }

</script>

<style scoped>
.sharebox{ width: 1198px; background: #ffffff; margin: 30px auto 0 auto;}
.item-info{ flex: 3;}
.item-info strong{ font-size: 17px; color: #666666; display: block; padding-bottom: 8px;}
.itemcontent{ flex: 2;}
.item-order{ flex: 1;}
.shareitem{border: 1px solid #eee;padding: 20px; margin-top: 18px;}
.shareitem:hover{ box-shadow: rgba(0,0,0,0.05) 0 2px 5px;}
.info-place{ margin-top: 10px;}
.item-order div{ margin:2px 0;}
.addbox{ margin-top: 30px;}


</style>

