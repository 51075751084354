<template>
    <el-dialog
        title="详情"
        :visible.sync="show"
        width="45%"
        :before-close="handleClose">
        <div>
            <div class="showitem flex align-center">
                <span>企业名称：</span><p>{{ selectobj?.memberName }}</p>
            </div>
            <div class="showitem flex align-center">
                <span>用工类型：</span><p>{{ selectobj?.industryName }}</p>
            </div>
            <div class="showitem flex align-center">
                <span>用工人数：</span><p>{{ selectobj?.needPerson }}</p>
            </div>
            <div class="showitem flex align-center">
                <span>供方/需方：</span><p>{{ selectobj?.supplyStr }}</p>
            </div>
            <div class="showitem flex align-center">
                <span>联系电话：</span><p>{{ selectobj?.mobile }}</p>
            </div>
            <div class="showitem flex align-center">
                <span>用工描述：</span><p>{{ selectobj?.description }}</p>
            </div>
        </div>
       <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
<!--        <el-button type="primary" @click="confirm">确 定</el-button>-->
      </span>
    </el-dialog>
</template>

<script>
export default{
    props:{
        show:{
            type:Boolean,
            default:false
        },
        selectobj:{
            type:Object,
            default:null
        }
    },
    data(){
        return{

        }
    },
    methods:{
        handleClose(){
            this.$emit("update:show",false)
        },
        confirm(){

        }
    }
}
</script>

<style scoped>
.showitem{ margin-top: 30px;}
.showitem span{ display: block; width: 100px; text-align: right;}
</style>

